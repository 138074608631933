import React from 'react';
import {paymentSuccess} from '../../actions/paymentActions';
import { useDispatch } from 'react-redux'


export default function TotalModal(props) {
  
  
  var subtotal_price = 0;
  
  for (let i = 0; i < props.data.length; i++) {
    let data = props.data[i];
    subtotal_price = subtotal_price + data.interest_payable100;
  }
  


sessionStorage.setItem('admin_price', '0' );
sessionStorage.setItem('subtotal_price', subtotal_price/100 );
sessionStorage.setItem('total_price', subtotal_price/100 );

  
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return (<div>
    
    
    Int: {formatter.format(subtotal_price/100)}

    
  </div>);
}
