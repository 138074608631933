import {FETCH_TICKET, FETCH_TODO} from '../reducers/types/ticketTypes';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jwt from 'jsonwebtoken'
import setAuthorizationToken from './authActions'
import { trackPromise } from 'react-promise-tracker'
import {logout} from '../actions/authActions';


const apiUrl = 'https://pdmsonlinebanfooksoutheast.azurewebsites.net/online-pdms-banfook/public/api'


const config = setAuthorizationToken(localStorage.jwtToken)
const auth = jwt.decode(localStorage.jwtToken)


axios.interceptors.response.use(undefined, (err) => {
    const { config, message } = err;
    if (!config || !config.retry) {
      return Promise.reject(err);
    }
    // retry while Network timeout or Network Error
    if (!(message.includes("timeout") || message.includes("Network Error"))) {
  //    toast.error("err 2")
   //   return Promise.reject(err);
    }
    config.retry -= 1;
    const delayRetryRequest = new Promise((resolve) => {
      setTimeout(() => {
        toast.error("Please wait")
        console.log("retry the request", config.url);
        resolve();
      }, config.retryDelay || 1000);
    });
    return delayRetryRequest.then(() => axios(config));
  });

  
export const getAllTicket = () => async dispatch =>{

    const {username} = auth

    const token = localStorage.jwtToken;
    var decodedToken=jwt.decode(token, {complete: true});
    var dateNow = new Date();

    let shop = sessionStorage.getItem('shop')  
   // toast.error(shop)

    const {mytoken} = localStorage.getItem('jwtToken')
    

    try{
        
        const res1 =    await trackPromise(axios.post(`${apiUrl}/auth/getshopname`, {username}, config))
        if(res1.data){
       // toast.error("Read OK "+mytoken);
      //  toast.error(res1.data.data);
    //  var shop = res1.data.data
  //    sessionstorage.setItem('shop',shop)
   //   localstorage.setItem('shop',shop)
 //     sessionStorage.setItem('shop', shop)  
   //   localStorage.setItem('shop', shop)  
    
  //    toast.error(shop);
        }
        else{ 
        }

        var encryptedStr = localStorage.getItem('data.orderIDInLocalStorage' );
    
        toast.success("Retrieving: "+shop);

        try{
      //      const res = await trackPromise(axios.post(`${apiUrl}/ticket`,{username}, config))
            var s1 = `https://pdmsonlinebanfooksoutheast.azurewebsites.net/online-pdms-banfook/public/api/dra/ticket?username=`+username;
            // toast.success(s1);
     
                 const res = await trackPromise(axios.get(s1, config))
            sessionStorage.setItem('username', username );
          
            if(res.data.invalid_token  ){
                console.log(res.data)
                localStorage.removeItem('jwtToken')
                window.location.replace("/login");
    
            }
            else if(res.data.data == "No Data Found"){
            //    toast.error(res.data.data);
                return false
    
            }
           
            dispatch({
                type: FETCH_TICKET,
                payload: res.data
             });
            } catch (error){
    
                return "Internal server error"
            }
    


        } catch (error){

      //  toast.error("Read Error");
           sessionStorage.setItem('Logout', 'done');
           localStorage.removeItem('jwtToken')
           dispatch(logout())
        }
     

 

    
    
    
}

export const getAllTodoList = () => async dispatch =>{

    const {username} = auth

    toast.success("Retrieving Data");
        

    try{
        const res = await trackPromise(axios.post(`${apiUrl}/ticket`,{username}, config))

        if(res.data.invalid_token  ){
            console.log(res.data)
            window.location.replace("/login");

        }
        else if(res.data.data == "No Data Found"){
         //   toast.error(res.data.data);
            return false

        }
       
        dispatch({
            type: FETCH_TODO,
            payload: res.data
         });
        } catch (error){

            return "Internal server error"
        }


}